// @import '../../styles/1-tools/_vars.sass'

.margin_t_10 {
  margin-top: 10px; }

.margin_t_20 {
  margin-top: 20px; }

form {
  .rc-tabs {
    .rc-tabs-bar {
      .rc-tabs-nav-wrap {
        padding: 0 15px 1.5rem 15px; } } } }
