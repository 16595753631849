// @import '../../styles/1-tools/_vars.sass'

.maxHeight {
  height: 80vh; }
.required {
  color: red; }
.page-title-image {
  background-size: contain!important;
  background-position: 50%!important;
  background-repeat: no-repeat!important; }
