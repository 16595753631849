.notice_residents_modal {
  .modal-body {
    min-height: 50vh; }
  .resident_select.select_container {
    display: flex;
    .dropdown {
      margin: 0 .5rem;
      .dropdown-menu.show {
        max-height: 400px;
        overflow: auto; } } } }
