.dropzone-wrapper {
  cursor: pointer;
  .dropzone-content {
    p {
      user-select: none; } } }
.image_error {
  color: #d92550;
  white-space: pre; }
.dropzone_preview.list-group-item {
  display: flex;
  align-items: center;
  .img_container {
    $sideSize: 75px;
    width: $sideSize;
    margin-right: 1rem;
    img {
      display: block;
      max-width: 100%;
      height: auto; } } }
.cancellBtn {
  color: white;
  background-color: #d92550;
  font-size: 20px;
  display: block;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1rem; }
